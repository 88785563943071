/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import "./theme/leaflet.scss";

@font-face {
    font-family: "Nunito";
    src: url("assets/fonts/Nunito-ExtraBold.woff2") format("woff2"), url("assets/fonts/Nunito-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("assets/fonts/Nunito-Regular.woff2") format("woff2"), url("assets/fonts/Nunito-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("assets/fonts/Nunito-Bold.woff2") format("woff2"), url("assets/fonts/Nunito-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
    // font-size: 14px;
    font-family: "Nunito", sans-serif !important;
    font-weight: 500;
    --highlight-background: black;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f4f5f8;
}

::-webkit-scrollbar-thumb {
    background: var(--ion-color-light);
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--ion-color-medium);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    color: var(--ion-color-dark);
}

ion-input,
ion-textarea,
ion-select {
    border: 1px solid #e4e6ef;
    --padding-start: 13px !important;
    --padding-top: 13px !important;
    --padding-bottom: 13px !important;
    border-radius: 6px;
    --placeholder-color: #a1a5b7 !important;
    --placeholder-opacity: 0.9 !important;
    &.no-border {
        border: 0px;
    }
    span {
        font-weight: 600;
        margin-left: 16px !important;
        color: #adadad;
        font-size: 17px;
    }
}

ion-icon {
    &[class*="icon-"] {
        mask-size: contain;
        mask-repeat: no-repeat;
        background: currentColor;
    }
    // custom icons
    &[class*="icon-visa"] {
        mask-image: url(../src/assets/icons/visa.svg);
    }
    &[class*="icon-amex"] {
        mask-image: url(../src/assets/icons/mastercard.svg);
    }
    &[class*="icon-mastercard"] {
        mask-image: url(../src/assets/icons/amex.svg);
    }
}

ion-item {
    --background: transparent;
}

ion-tab-bar {
    border: 0;
}

.tab-home {
    --color-selected: var(--ion-color-secondary);
    transition: all 0.2s ease-out;
    text-align: center;
    position: relative;
    ion-icon {
        background: var(--ion-color-primary);
        width: 50px;
        height: 30px;
        text-align: center;
        padding: 8% 0;
        color: var(--ion-color-secondary);
        border-radius: 50px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 5px;
        margin: auto;
    }
}

ion-tab-button:first-child {
    border-radius: 16px 0 0 0;
}

ion-tab-button:last-child {
    border-radius: 0 16px 0 0;
}

ion-content {
    // --background: linear-gradient(-180deg, #1F252C 0%, #2E323A 51%);
}

.button-left {
    .button-inner {
        justify-content: left !important;
    }
}

.datetime-modal {
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
}

// .ion-datetime ion-datetime {
//     height: 382px;
// }
.addPaymentModal {
    --height: 70%;
    bottom: 0;
    align-items: flex-end;
    --border-radius: 37px 37px 0 0;
}

ion-item {
    background: transparent;
}

ion-tab-button {
    .button-native::after {
        background: transparent !important;
    }
}

.auth-header {
    z-index: 1111;
    width: 100%;
    z-index: 1111;
    width: 100%;
    background: white;
    ion-button {
        --color: var(--ion-color-dark);
    }
    .logo {
        cursor: pointer;
        width: 30%;
        img {
            width: 120px;
        }
    }
    ion-progress-bar {
        width: 40%;
        height: 7px;
        border-radius: 10px;
    }
    ion-item {
        width: 30% !important;
        text-align: right;
        cursor: pointer;
        ion-label {
            padding: 0;
        }
    }
    .button-has-icon-only {
        ion-icon {
            font-size: 20px !important;
            color: #8289a5;
        }
    }
}

.d-flex {
    display: flex !important;
    gap: 13px;
    ion-item {
        width: 100%;
    }
}

.ml-auto {
    margin-left: auto !important;
}

.infinite-loading-spinner {
    ion-spinner {
        margin-bottom: 16px !important;
    }
}

.w {
    &--100 {
        width: 100% !important;
    }
    &--90 {
        width: 90% !important;
    }
    &--80 {
        width: 80% !important;
    }
    &--70 {
        width: 70% !important;
    }
    &--60 {
        width: 60% !important;
    }
    &--50 {
        width: 50% !important;
    }
    &--40 {
        width: 40% !important;
    }
    &--30 {
        width: 30% !important;
    }
    &--20 {
        width: 20% !important;
    }
    &--10 {
        width: 10% !important;
    }
}

ion-list {
    background: transparent !important;
}

ion-modal.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.text-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ecedf1;
    border-radius: 6px;
    h3 {
        font-size: 30px;
        font-weight: 600;
        color: var(--ion-color-medium);
    }
}

.quantity-input {
    display: flex;
    gap: 10px;
    border-radius: 6px;
    padding: 10px;
    ion-button {
        // height: 100%;
        // margin: 0;
        ion-icon {
            font-size: 30px;
            --ionicon-stroke-width: 80px;
        }
    }
    ion-input {
        font-weight: 600;
        text-align: center;
        --padding-start: 0 !important;
        --padding-end: 0;
        font-weight: 700;
        border: none;
        font-size: 30px;
        --placeholder-color: var(--ion-color-medium);
        --placeholder-opacity: 0.5;
    }
}

.remove-button {
    color: var(--ion-color-danger) !important;
}

.cancel-button {
    color: var(--ion-color-secondary) !important;
}

.tracking-css {
    align-items: center;
    justify-content: center;
    --min-height: 60%;
    --min-width: 90%;
    --max-height: 50%;
    --max-width: 90%;
    --border-radius: 20px;
  }
  
  select {
	border: 1px solid #dedede;
	padding: 13px;
	background: transparent;
	font-weight: 400;
	border-radius: 6px;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	outline: none;
    color: #fff;
	background-size: 12px;
}

.flex {
	display: flex;
	gap: 13px;

	ion-item {
		width: 100%;
	}

	&--gap {
		&-0 {
			gap: 0 !important;
		}

		&-5 {
			gap: 5px !important;
		}

		&-16 {
			gap: 16px !important;
		}

		&-32 {
			gap: 32px !important;
		}
	}
}